/* 

GTM code -- currently not used

<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','GTM-WBDJ96F');</script>
<link href="https://fonts.googleapis.com/css?family=Oswald:200,300,400" rel="stylesheet"> 


*/

import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { Switch, useLocation } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';

// Initialize Google Analytics
ReactGA.initialize('G-29HQBVR3QC'); //process.env.REACT_APP_GA_CODE);

const trackPage = page => {
	//ReactGA.set({ page });
	//ReactGA.pageview(page);
	ReactGA.send({ hitType: "pageview", page });
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
			<AppRoute exact path="/" component={Home} layout={LayoutDefault}/>
			{/* <AppRoute exact path="/sign-up" component={SignUp} layout={LayoutDefault}/> */}
			{/*<AppRoute exact path="/contact" component={Contact} layout={LayoutDefault}/>
			<AppRoute exact path="/demo" component={Demo} layout={LayoutDefault}/>
			<AppRoute exact path="/subscribed" component={Subscribed} layout={LayoutDefault}/> */}
			{/* <AppRoute exact path="/free-audit" component={Audit} layout={LayoutDefault}/> */}
			{/* <Route path="/privacy.html" onEnter={() => window.location.reload()} />
			<Route path="/terms.html" onEnter={() => window.location.reload()} /> */}
        </Switch>
      )} />
  );
}

export default App;