import classNames from 'classnames';
import React from 'react';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  /*
  const sectionHeader = {
    title: 'Workflow that just works',
    paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };
  */

  return (
    <section
	  id='features-split'
      {...props}
      className={outerClasses}
    >
		
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
		  
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Experiences
                </div>
                <h3 className="mt-0 mb-12">
                  Manage your experiences for testing and personalization
                </h3>
                <p className="m-0">
                  Verra's powerful experience editor gives you the control you need to build highly tailored experieneces for testing and personalization
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src='assets/images/experiences-edit.png'
                  alt="Features split 01"
                  width={528}/>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
					Audiences
                  </div>
                  <h3 className="mt-0 mb-12">
                  Build audiences for serving personalized experiences and segmentation for testing
                  </h3>
                  <p className="m-0">
                  The audience builder allows you fine grain control over customer segmentation, ensuring you maximize your personalization and testing efforts
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src='assets/images/audiences.png'
                  alt="Features split 02"
                  width={528}/>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Insights
                  </div>
                <h3 className="mt-0 mb-12">
                  Create custom metrics that drive powerful analytics
                </h3>
                <p className="m-0">
					Build optimizations with the metrics that are most important to you and let Verra's powerful analytics surface deeper insights
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src='assets/images/optimization-analytics.png'
                  alt="Features split 03"
                  width={528}/>
              </div>
            </div>

			{/* <div style={{ display: 'flex', justifyContent: 'center' }} >
				<Link to="/demo/" className="button button-primary button-wide-mobile button-sm">Request Demo</Link>
			</div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;