import React from 'react';
import Beta from '../components/sections/Beta';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Hero from '../components/sections/Hero';

const Home = () => {

	document.title = 'Verra - AI Powered Experience Optimization';

	React.useEffect(() => {

		const vid2 = document.getElementById('bg-vid-2');
		vid2.load();
		
		const scrollListener = function(e){
			
			try {
				//const start = vid2.seekable.start(0);  // Returns the starting time (in seconds)
				if( vid2 != null && vid2.seekable != null ) {
					const end = vid2.seekable.end(0);    // Returns the ending time (in seconds)
					const timeMultiplier = 50;
					const endOffset = end * timeMultiplier;
					const startY = 880;
					const endY = 3225;
					const delta = endY - startY;
					const offsetY = window.scrollY - startY;
					const time = Math.round(offsetY / delta * endOffset) / timeMultiplier;
			
					vid2.currentTime = time;
				}
			} catch ( e ) { }

		};
	
		var hasIntersectionObserver = false;
		var ivl = null;

		const container = document.getElementById('features-split');

		if(container != null && !hasIntersectionObserver){
			const options = {
				root: null,
				rootMargin: "0px",
				threshold: 0.0
			};
	
			const observer = new IntersectionObserver((entries) => {
				const isIntersecting = entries[0].isIntersecting;
				if(isIntersecting){
					//ivl = setInterval(scrollListener, 10);
					document.addEventListener('scroll', scrollListener);
				} else {
					clearInterval(ivl);
					document.removeEventListener('scroll', scrollListener);
				}

			}, options);

			observer.observe(container);
		}
	}, []);

	return (
		<div id='verra-container'>
			<div id='bg-vid'>
				<video id='bg-vid-1' playsInline autoPlay muted loop preload='auto'>
					<source src="https://verra-assets.s3.us-west-2.amazonaws.com/VerraBackground-5-25FPS-720.mp4" type="video/mp4"/>
				</video>
			</div>
			<div id='verra-content'>
				<Hero className="illustration-section-01" />
				<div className='features-block'>
					<video id='bg-vid-2' playsInline muted loop preload='auto'>
						<source src="https://verra-assets.s3.us-west-2.amazonaws.com/VerraBackground-3-25FPS-720.mp4" type="video/mp4"/>
					</video>
					<div className='features-block-content'>
						<FeaturesTiles/>
					   	<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
						<Beta/>
					</div>
				</div>
			</div>
		</div>
	);
}


{/* <Products/> */}
						{/* <Testimonial topDivider /> 
						<Cta split />*/}

export default Home;